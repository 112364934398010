<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">{{ i18n('menuPage.title') }}</h2>
    </div>

    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5 dark:bg-theme-dark-2">
      <div
        class="
          flex flex-col
          sm:flex-row sm:items-end
          xl:items-start
          justify-end
        "
      >
        <div class="flex mt-5 sm:mt-0 gap-3">
          <button
            v-if="hasPermissionToCreateMenu"
            class="btn bg-theme-31 text-white sm:w-auto py-2"
            @click="$router.push('/menu/new')"
            :disabled="!hasPermissionToCreateMenu"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
          <a
            href="javascript:;"
            data-toggle="modal"
            data-target="#request-modal-preview"
            class="btn bg-theme-31 text-white sm:w-auto py-2"
          >
            <InfoIcon class="w-5 h-5 mr-2 ml-2" />
            {{ i18n('common.request') }}
          </a>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="half-circle"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <table class="table table-report sm:mt-2">
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center">#</th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  class="whitespace-nowrap text-center"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="w-20 text-center">
                  <div class="text-gray-600 text-xs whitespace-nowarp mt-0.5">
                    {{ index + 1 }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterLocalization(row, 'name') }}
                  </div>
                </td>
                <td class="flex items-center justify-center">
                  <div class="font-medium whitespace-nowrap text-gray-600">
                    <!-- <div class="flex-none rounded-md overflow-hidden w-64">
                      <a
                        href="javascript:;"
                        @click="openPDF(row.file)"
                        class="text-theme-24 flex items-center"
                      >
                        <pdf src="https://firebasestorage.googleapis.com/v0/b/nfc-menu-system.appspot.com/o/meuns%2FzD0dOGBJJwYG4v3uRWT3a9AppEC3%2FMenu-1.pdf?alt=media&token=0165a8db-3a2c-4a28-bbff-610c979e755b"></pdf>
                      </a>
                    </div> -->
                    <div
                      class="
                        flex-none
                        rounded-md
                        overflow-hidden
                        w-64
                        flex
                        items-center
                        gap-4
                      "
                    >
                      <!-- <pdf :src="row.file" class="pdf-thumbnail"></pdf> -->
                      <embed
                        width="191"
                        height="207"
                        name="plugin"
                        :src="row.file"
                        class="embed-pdf-scroll"
                        type="application/pdf"
                        @click="openPDF(row.file)"
                      />
                      <div class="flex flex-col justify-center items-center">
                        <a
                          :href="row.file"
                          target="_blank"
                          class="text-theme-31 flex items-center w-12 h-12"
                        >
                          <EyeIcon class="w-5 h-5 mx-3" />
                        </a>
                        <div class="text-theme-31 flex items-center justify-center w-12 h-12">
                          <CopyIcon
                            class="cursor-pointer w-5 h-5 mr-2 ml-2"
                            @click="copyFileURL(row.file)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="table-report__action w-52">
                  <div class="flex justify-center items-center">
                    <router-link
                      class="text-theme-main flex items-center cursor-pointer"
                      :to="`/menu/${row.id}/edit`"
                    >
                      <RepeatIcon class="w-4 h-4 mr-1" />
                      <I18n code="common.update"></I18n>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- BEGIN: Modal -->
    <RequestMenu item="menu" />
    <!-- <PDFViewer initialDoc="https://firebasestorage.googleapis.com/v0/b/nfc-menu-system.appspot.com/o/meuns%2FzD0dOGBJJwYG4v3uRWT3a9AppEC3%2FMenu-1.pdf?alt=media&token=0165a8db-3a2c-4a28-bbff-610c979e755b" /> -->
    <!-- END: Modal -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import RequestMenu from './request-menu.vue'
import Message from '@/shared/message/toastify'
// import PDFViewer from './menu-viewer.vue'
// import pdf from 'vue3-pdf'
import { GenericModel } from '@/shared/model/generic-model'
// import pdfFile from '@/assets/menu.pdf'

// const loadingTask = pdf.createLoadingTask('')

export default defineComponent({
  components: {
    // pdf,
    // PDFViewer,
    RequestMenu
  },
  data() {
    return {
      selectedPdf:
        'https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf',
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'menuPage.fields.name',
          align: 'center'
          // required: true,
        },
        {
          name: 'file',
          field: 'file',
          label: 'menuPage.fields.file',
          align: 'center'
          // required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'common.createdAt',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      hasPermissionToCreateMenu: 'auth/hasPermissionToCreateMenu',
      rows: 'menu/list/rows',
      loading: 'menu/list/loading'
    })
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.menu')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.menu')
    }
  },
  async mounted() {
    await this.doFetch()
  },
  methods: {
    ...mapActions({
      doFetch: 'menu/list/doFetch'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    copyFileURL(url) {
      navigator.clipboard.writeText(url)
      Message.success(this.i18n('common.clipboard'))
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    openPDF(link) {
      // const link = `/${this.language}/pdf/${file.id}`
      // const link = `/${this.language}/pdf/${file.id}`
      const anchor = document.createElement('a')
      anchor.href = link
      anchor.target = '_blank'
      document.body.appendChild(anchor)
      anchor.click()
    }
  }
})
</script>

<style lang="scss">
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.dark {
  .table-report:not(.table-report--bordered):not(.table-report--tabulator) {
    td,
    th {
      background-color: #0003 !important;
    }
  }
}

.delete-icon {
  position: absolute;
  width: 80px;
  cursor: pointer;
  margin-top: 200px;
  margin-left: 10px;
  @media only screen and (max-width: 1440px) {
    width: 80px;
    margin-top: 120px;
  }
  @media only screen and (max-width: 1024px) {
    width: 80px;
    margin-top: 80px;
  }
  @media only screen and (max-width: 768px) {
    width: 80px;
    margin-top: 120px;
  }
  @media only screen and (max-width: 425px) {
    width: 80px;
    margin-top: 60px;
  }
  @media only screen and (max-width: 375px) {
    width: 60px;
    margin-top: 60px;
  }
  @media only screen and (max-width: 320px) {
    width: 60px;
    margin-top: 40px;
  }
  @media only screen and (max-width: 300px) {
    width: 60px;
    margin-top: 20px;
  }
}
[dir='rtl'] .delete-icon {
  margin-left: unset !important;
  margin-right: 10px !important;
}
</style>
<style lang="scss">
.embed-pdf-scroll::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  // color: $Pelorous !important;
  // background: $Pelorous;
  border-radius: 50px;
}
.embed-pdf-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
.embed-pdf-scroll::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: black !important;
  // outline: 5px solid greenyellow !important;
}
</style>
